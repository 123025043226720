@import './variables';

.service_section {
  margin-bottom: 140px;

  @media (max-width: 991px) {
    margin-bottom: 80px;
  }

  @media (max-width: 767px) {
    margin-bottom: 60px;
    padding-bottom: 80px;
  }

  h2 {
   display: flex;
    flex-direction: column;
  }

  .service_head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;

    @media (max-width: 767px) {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 30px;
      margin-bottom: 30px;
    }

    p {
      display: flex;
      flex-direction: column;
    }
  }

  .arrow_slider {
    z-index: 2;
    @media (max-width: 767px) {
      top: auto;
      bottom: -80px;
    }
  }
  .arrow_prev {
    right: auto;
    left: -75px;
    transform: rotate(180deg);
    @media (max-width: 767px) {
      left: 0;
    }
  }
  .arrow_next {
    right: -75px;
    @media (max-width: 767px) {
      right: 0;
    }
  }

  .service_container {
    max-width: calc(100% - 150px);
    margin: 0 auto;

    @media (max-width: 767px) {
      max-width: 100%;
    }
  }

  .slick-slide {
    padding: 0 10px;
  }

  .service_item {
    border: 3px solid $second_main_color;
    border-radius: 16px 16px 47px 47px;
    overflow: hidden;
    padding-bottom: 16px;

    .service_img {
      height: 200px;
      width: 100%;
      background-size: cover;
      background-position: center;
    }

    .service_content {
      padding: 30px 16px 30px 16px;

      .service_title {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 16px;
      }
      .service_text {
        font-size: 18px;
      }
    }
    .main_btn {
      box-shadow: -3px -3px 17px 0 #fff, 3px 3px 17px 0 rgba(85, 93, 36, 0.3);
      background: url("../img/arrow-w.svg") no-repeat center right 15px $second_main_color;
      background-size: 15px;
      border-color: $second_main_color;
      padding: 15px 45px 15px 15px;
      font-weight: 400;
      font-size: 16px;
      color: #fff;
      text-transform: uppercase;
      transition: 0.3s ease;

      &:hover {
        background: url("../img/arrow-g.svg") no-repeat center right 15px;
        background-size: 15px;
        color: #424242;
      }
    }
    .button_service {
      display: flex;
      justify-content: center;
    }
  }

}
