@import './variables';

.header_section {
  margin-top: 130px;
  display: flex;
  gap: 30px;

  @media (max-width: 767px) {
    flex-direction: column-reverse;
    gap: 70px;
    margin-top: 100px;
  }

  h1 {
    font-size: 64px;
    max-width: 630px;
    font-weight: 500;
    padding-top: 100px;
    line-height: 110%;

    @media (max-width: 991px) {
      font-size: 32px;
      padding-top: 50px;
    }
    @media (max-width: 767px) {
      text-align: center;
      padding-top: 30px;
    }
  }

  .head_btn {
    display: flex;
    justify-content: space-between;
    max-width: 580px;
    margin-top: 100px;

    @media (max-width: 991px) {
      justify-content: flex-start;
      gap: 30px;
      margin-top: 50px;
    }
    @media (max-width: 767px) {
      justify-content: center;
      gap: 15px;
      margin-top: 30px;
    }
  }

  .head_slider {
    position: relative;
    width: 50%;
    border-radius: 341px 0 0 341px;
    overflow: hidden;
    margin-top: 15px;
    box-shadow: 0 0 17px 0 rgba(0, 0, 0, 0.25);
    height: 500px;

    @media (max-width: 1325px) {
      margin-right: -15px;
    }
    @media (max-width: 1199px) {
      border-radius: 30px 0 0 30px;
      height: 400px;
    }
    @media (max-width: 991px) {
      height: 340px;
      width: 85%;
    }

    @media (max-width: 767px) {
      width: 100%;
      max-width: 375px;
      height: 253px;
      border-radius: 30px;
      margin: 0 auto;
      overflow: visible;
    }

    .slick-list {
      @media (max-width: 767px) {
        border-radius: 30px;
        height: 253px;
      }
    }
  }

  .head_title {
    @media (max-width: 767px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  .slick-slide img {
    @media (max-width: 1199px) {
      height: 400px;
    }
    @media (max-width: 991px) {
      height: 340px;
    }
    @media (max-width: 767px) {
      height: 253px;
    }
  }

  @media (max-width: 767px) {
    .arrow_slider {
      top: auto;
      right: 10px;
      bottom: -70px;

      &.arrow_prev {
        right: auto;
        left: 10px;
        transform: rotate(180deg);
      }
    }
  }

}