@import './variables';

.contact_section {
  background: #F6F3F0;
  border-radius: 30px;
  border: 1px solid $second_main_color;
  padding: 100px 50px 0;
  display: flex;
  justify-content: space-between;

  @media (max-width: 991px) {
    flex-direction: column;
    gap: 50px;
    padding: 60px 40px;
  }

  @media (max-width: 767px) {
    padding: 25px;
  }

  .error_message, .success_message {
    position: absolute;
    bottom: 89px;
    font-size: 15px;
    color: #e75d5d;
  }
  .success_message {
    color: $second_main_color;
  }
  .form_col {
    flex-basis: 50%;

    &.form_container {
      max-width: 450px;

      @media (max-width: 991px) {
        max-width: 100%;
        width: 523px;
      }
    }
  }

  h2 {
    font-size: 50px;
    margin-bottom: 60px;
    max-width: 450px;

    @media (max-width: 991px) {
      text-align: center;
      margin: 0 auto 50px;
      font-size: 32px;
    }
  }

  .form_container {
    margin-bottom: 50px;
    @media (max-width: 991px) {
      margin: 0 auto;
    }

    form {
      display: flex;
      flex-direction: column;
      gap: 30px;
      position: relative;

      input, textarea {
        border-radius: 26px;
        border: 1px solid #D4D4D4;
        background: #fff;
        color: #515151;
        padding: 22px 40px;
        font-size: 20px;

        @media (max-width: 767px) {
          padding: 15px;
          font-size: 16px;
        }

        &.error {
          border: 1px solid #ff9090;
        }
      }
      textarea {
        max-width: 450px;
        min-width: 100%;
        box-sizing: border-box;
        min-height: 70px;
      }

      .form_send {
        box-shadow: -4px -4px 20px 0 #fff, 3px 3px 20px 0 rgba(85, 93, 36, 0.3);
        background: #9a7549;
        font-weight: 400;
        font-size: 18px;
        color: #fff;
        padding: 30px 40px;
        border: none;
        border-radius: 50px;
        transition: 0.3s ease;
        display: inline-block;
        margin: 0 auto;
        position: relative;
        text-transform: uppercase;

        @media (max-width: 991px) {
          padding: 15px 15px;
          font-size: 16px;
        }

        &:hover {
          box-shadow: -4px -4px 20px 0 #fff, 3px 3px 20px 0 rgba(85, 93, 36, 0.8);
          background: #87653d;
        }
      }
    }
  }

  .contact_list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    li {
      padding-left: 35px;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        top: calc(50% - 10px);
        left: 0;

      }

      &.contact_phone:before {
        background: url("../img/phone_i.svg") no-repeat center;
        background-size: contain;
      }
      &.contact_mail:before {
        background: url("../img/mail_i.svg") no-repeat center;
        background-size: contain;
      }
      &.contact_adress:before {
        background: url("../img/geo_i.svg") no-repeat center;
        background-size: contain;
      }
    }
  }

  .contect_form_container {
    background: url("../img/stove_contact.svg") no-repeat bottom right;
    background-size: 290px;

    @media (max-width: 991px) {
      background-size: contain;
    }

    @media (max-width: 767px) {
      background-size: 80px;
      background-position: bottom 80px right;
    }

  }
  .loader {
    position: absolute;
    width: 30px;
    height: 30px;
    top: calc(50% - 15px);
    right: -60px;
    background: url("../img/loader.gif") no-repeat center;
    background-size: contain;
    opacity: 0.8;
    display: none;
  }
}