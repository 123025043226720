@import './variables';

.section-nav {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  background: $dark_color;
  box-shadow: 0 0 0 0 #8c9648;
  transition: 0.2s ease;

  .media_mobile {
    display: none;

    @media (max-width: 1070px) {
      display: block;
    }
  }

  @media (max-width: 1070px) {
    z-index: 999;
    padding-top: 10px;
    position: fixed;
    padding-bottom: 10px;
  }

  &.active {
    box-shadow: 0px 0px 7px 0px #8c9648;
  }

  .navigation {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .logo {
    @media (max-width: 991px) {
      max-height: 50px;
    }
  }

  .onscroll {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      transition: width 0.2s ease-in-out;
      background: #f6373796;
      box-shadow: 0 0px 8px 1px $main_color;
  }
.head_contacts {
  display: flex;
  align-items: center;
  background: url("../img/phone_i.svg") no-repeat center left;
  background-size: 25px;
  @media (max-width: 767px) {
    display: none;
  }
  a {
    padding-left: 40px;
  }

}
  nav {
    display: flex;
    align-items: center;
    @media (max-width: 1070px) {
      position: absolute;
      right: -100%;
      background: $main_color;
      z-index: 999;
      top: 84px;
      padding: 25px;
      box-shadow: 0 0px 7px 0px rgba(140, 150, 72, 0.65);
      border-radius: 20px 0 0 20px;
      transition: 0.5s cubic-bezier(0,.06,.66,1.57);

      &.active {
        right: 0;
      }
    }

    @media (max-width: 1070px) {
      flex-direction: column;
      gap: 50px;

      .mobile_contact {
        font-weight: 700;
        font-size: 18px;
      }
    }

    ul {
      display: flex;
      gap: 40px;

      @media (max-width: 1070px) {
        flex-direction: column;
      }


      a {
        padding: 8px 25px;
        position: relative;
        transition: color 0.3s ease;
        font-size: 25px;

        @media (max-width: 1070px) {
          font-size: 18px;
          padding: 8px 0;
        }

        &:before {
          content: '';
          width: 0;
          height: 1px;
          background: #8C9648;
          position: absolute;
          bottom: 0;
          left: 0;
          transition: 0.3s ease;
        }
        &:hover, &.active {
          &:before {
            width: 100%;
          }
        }
      }
    }
  }
}

.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: -12px;
  margin-bottom: -25px;
}

.line {
  fill:none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: #424242;
  stroke-width:3;
  stroke-linecap:round;
}

.hamburger {
  display: none;
  @media (max-width: 1067px) {
    display: block;
  }
}
.ham8 {
  .top {
    stroke-dasharray: 40 160;
  }
  .middle {
    stroke-dasharray: 40 142;
    transform-origin: 50%;
    transition: transform 400ms;
  }
  .bottom {
    stroke-dasharray: 20 85;
    transform-origin: 50%;
    transition: transform 400ms, stroke-dashoffset 400ms;
  }
  &.active {
    .top {
      stroke-dashoffset: -64px;
    }
    .middle {
      //stroke-dashoffset: -20px;
      transform: rotate(90deg);
    }
    .bottom {
      stroke-dashoffset: -64px;
    }
  }
}

.hamburder {
  display: none;

  @media (max-width: 1070px) {
    display: block;
    position: relative;
    z-index: 99999;
  }
}