//colors
$main_color: #EDE8E2;
$second_main_color: #8C9648;
$color_white: #fff;
$main_text_color: #424242;
$dark_color: #EDE8E2;
$accent_color: #9A7549;
$experience_list: #dadada;
$footer_color: #15141A;

// fonts
$title_size: 30px;
$h1_title: 55px;
$h1_title_tab: 45px;
$h1_title_mob: 35px;

// Functions
