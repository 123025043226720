@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Roboto-Bold.ttf') format('ttf');
  src: url('../fonts/Roboto-Bold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Roboto-Regular.ttf') format('ttf');
  src: url('../fonts/Roboto-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../fonts/Roboto-Medium.ttf') format('ttf');
  src: url('../fonts/Roboto-Medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}