@import './variables';

.section-footer {
  border-top: 3px solid $second_main_color;
  padding: 70px 0 70px;
  margin-top: 150px;
  position: relative;

  @media (max-width: 991px) {
    margin-top: 100px;
  }

  &:before {
    content: '';
    width: 215px;
    height: 215px;
    background: url("../img/stove_footer.svg") no-repeat center;
    background-size: contain;
    position: absolute;
    top: -215px;
    right: 0;
    z-index: -1;

    @media (max-width: 991px) {
      width: 150px;
      height: 150px;
      top: -150px;
    }
  }

  @media (max-width: 767px) {
    padding: 50px 0 50px;
  }
}
.footer-container {
  display: flex;
  justify-content: space-between;
  @media (max-width: 1000px) {
    flex-wrap: wrap;
    gap: 50px;
    padding: 0 15px;
  }
  @media (max-width: 991px) {
    display: grid;
  }
  @media (max-width: 767px) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }
  .logo-col {
    text-align: center;
    @media (max-width: 1000px) {
      width: 100%;
    }

    @media (max-width: 991px) {
      width: 100%;
    }
  }
  .footer-col {
    @media (max-width: 767px) {
      width: 334px;
      max-width: 100%;
      text-align: left;
    }

    &.footer-col-contact {
      @media (max-width: 991px) {
        grid-column: 2;
        grid-row: 1;
      }
    }
    &.footer-col-menu {
      @media (max-width: 991px) {
        grid-column: 1;
      }
    }
  }

  .footer_text {
    font-size: 14px;
    max-width: 260px;
    text-align: left;

    @media (max-width: 991px) {
      margin-top: 25px;
      margin-bottom: 10px;
    }
  }
  .copyright {
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #A49B9B;
    margin-top: 20px;
    text-align: left;
    @media (max-width: 1000px) {
      margin-top: 0;
    }
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }

  .footer-col-title {
    font-family: "TT-Firs-Neue-Bold", sans-serif;
    font-weight: 300;
    font-size: 30px;
    line-height: 99%;
    letter-spacing: -0.03em;
    margin-bottom: 28px;
    @media (max-width: 767px) {
      font-size: 20px;
    }
  }

  ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 25px 80px;

    a {
      font-weight: 400;
      font-size: 22px;
      line-height: 100%;
      letter-spacing: -0.01em;
      color: #424242;
      transition: 0.2s ease;
      @media (max-width: 767px) {
        font-size: 18px;
      }

      &:hover {
        color: $second_main_color;
      }
    }
  }

  .contact-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .contact-element {
    display: flex;
    gap: 60px;
    position: relative;
    padding-left: 30px;
    @media (max-width: 767px) {
      gap: 20px;
    }

    &.tel {
      background: url("../img/phone_i.svg") no-repeat center left;
      background-size: 20px;
    }

    &.mail {
      background: url("../img/mail_i.svg") no-repeat center left;
      background-size: 20px;
    }

    &.geo {
      background: url("../img/geo_i.svg") no-repeat center left;
      background-size: 20px;
    }

    p {
      font-weight: 400;
      font-size: 22px;
      line-height: 100%;
      letter-spacing: -0.01em;
      color: #8f889a;
      min-width: 100px;
      @media (max-width: 767px) {
        font-size: 18px;
      }
    }

    a {
      font-weight: 400;
      font-size: 20px;
      line-height: 100%;
      letter-spacing: -0.01em;
      @media (max-width: 767px) {
        font-size: 18px;
      }
    }
  }
}