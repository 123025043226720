@import './variables';

.process_section {
  margin-top: 140px;
  position: relative;
  background-size: contain;

  &:before {
    content: '';
    width: 100%;
    height: 506px;
    background: url("../img/process_bg.svg") no-repeat center bottom;
    position: absolute;
    background-size: contain;
    bottom: -280px;
    left: 0;
    z-index: -1;

    @media (max-width: 991px) {
      height: 250px;
      bottom: -166px;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }

  @media (max-width: 991px) {
    margin-top: 80px;
  }

  @media (max-width: 767px) {
    margin-top: 60px;
  }

  h2 {
    max-width: 564px;
    margin-bottom: 60px;

    @media (max-width: 767px) {
      margin-bottom: 30px;
      max-width: 310px;
    }
  }

  .process_container {
    @media (max-width: 767px) {
      max-width: 100%;
      height: max-content;
      padding-bottom: 22px;
    }
   .mob_scroll {
     display: grid;
     grid-template-columns: repeat(3, 1fr);
     gap: 35px;

     @media (max-width: 991px) {
       grid-template-columns: repeat(2, 1fr);
     }
     @media (max-width: 767px) {
       display: flex;
       width: 600vw;
     }
   }


    .process_item {
      display: flex;
      gap: 35px;

      @media (max-width: 767px) {
        width: calc(100vw - 30px);
      }
      span {
        color: #8C9648;
        font-weight: 600;
        font-size: 70px;
        line-height: 100%;

        @media (max-width: 1199px) {
          font-size: 55px;
        }
        @media (max-width: 991px) {
          font-size: 48px;
        }
      }
      .step_title {
        font-weight: 400;
        font-size: 32px;
        color: #424242;
        line-height: 110%;
        margin-bottom: 15px;

        @media (max-width: 991px) {
          font-size: 18px;
        }
      }
      .step_content {
        font-weight: 400;
        font-size: 20px;
        color: #424242;
        line-height: 110%;

        @media (max-width: 991px) {
          font-size: 16px;
          white-space: break-spaces;
        }
      }
    }
  }
}