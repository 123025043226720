@import './variables';

.projects_section {
  padding-top: 100px;
  margin-bottom: 140px;
  position: relative;

  @media (max-width: 1199px) {
    padding-top: 50px;
  }

  @media (max-width: 991px) {
    margin-bottom: 80px;
  }

  @media (max-width: 767px) {
    margin-bottom: 60px;
    padding-top: 0;

    &:after {
      content: '';
      width: 100%;
      height: 100px;
      background: url("../img/process_bg.svg") no-repeat center bottom;
      position: absolute;
      background-size: contain;
      bottom: -70px;
      left: 0;
      z-index: -1;
    }
  }

  h2 {
    max-width: 645px;
    @media (max-width: 991px) {
      max-width: 410px;
    }
  }

  .MuiFormControl-root {
    width: 250px;
    background: $main_color;

    @media (max-width: 767px) {
      margin: 0 auto;
    }

    #projects-select-label {
      color: $second_main_color;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: $second_main_color;
      border-width: 2px;

      legend span {
        width: 70px;
      }
    }

    .custom-arrow-icon {
      right: 15px;
      position: absolute;
      display: flex;
    }

    .MuiInputBase-root {
      border-radius: 16px;
      color: #686656;

      &:hover {
        .MuiOutlinedInput-notchedOutline {
          border-color: $second_main_color;
        }
      }

      &.Mui-focused .MuiOutlinedInput-notchedOutline {
        border-color: $second_main_color;
      }
    }

  }

  .slick-slide {
    @media (max-width: 991px) {
      padding: 0 15px;
    }
  }

  .arrow_slider {
    top: auto;
    bottom: -113px;

    @media (max-width: 991px) {
      bottom: -83px;
    }

    @media (max-width: 767px) {
      right: 0;
    }

    &.arrow_prev {
      left: 50px;
      transform: rotate(180deg);

      @media (max-width: 767px) {
        left: 0;
      }
    }
  }

  .projects_head_nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;
    gap: 30px;

    @media (max-width: 767px) {
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      margin-bottom: 30px;
    }

    .desctop_nav {
      display: flex;
      gap: 15px;

      button {
        background: $main_color;
        border: 2px solid $second_main_color;
        border-radius: 78px;
        padding: 10px 25px;
        color: #686656;
        font-size: 20px;
        transition: 0.3s ease;
        box-sizing: border-box;

        &.active {
          background: $second_main_color;
          color: #fff;
        }
      }
    }
  }

  .projects_container {
    @media (min-width: 992px) {
      display: grid;
      gap: 35px;
      grid-template-columns: repeat(3, 1fr);
    }

    .project_item {
      border-radius: 30px;
      border: 3px solid $second_main_color;
      background: #f6f3f02e;
      background-size: cover;
      height: 385px;
      display: flex;
      justify-content: center;
      overflow: hidden;
      cursor: zoom-in;

      @media (max-width: 991px) {
        height: 277px;
      }

      img {
        height: 100%;
        width: auto;

        @media (max-width: 991px) {
          margin: 0 auto;
        }
      }
    }
  }

  .projects_buttons {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-top: 60px;

    @media (max-width: 767px) {
      margin-top: 30px;
    }

    button {
      background: url("../img/arrow_btn.svg") no-repeat center right 25px $main_color;
      border: 2px solid $second_main_color;
      border-radius: 78px;
      padding: 10px 60px 10px 25px;
      color: #424242;
      font-size: 20px;
      line-height: 100%;
      transition: 0.3s ease;
      box-sizing: border-box;
      box-shadow: none;

      &:hover {
        box-shadow: -4px -4px 20px 0 #fff, 3px 3px 20px 0 rgba(85, 93, 36, 0.3);
      }
    }

    .main_btn {
      border: 2px solid $second_main_color;
      box-sizing: border-box;
      background: $second_main_color;
      color: #fff;
      transition: 0.3s ease;
      font-size: 20px;

      @media (max-width: 991px) {
        padding: 15px 15px;
      }

      @media (max-width: 767px) {
        font-size: 18px;
      }

      &:hover {
        background: transparent;
        color: #424242;
      }
    }
  }
}


.select-menu {
  border-radius: 16px !important;
  background-color: $main_color !important;
  margin-top: 5px;
}