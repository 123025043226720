.project_page {
  padding-top: 160px;

  @media (max-width: 767px) {
    padding-top: 100px;
  }

  .projects_head_nav {
    @media (max-width: 1199px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  h1 {
    font-size: 40px;
    max-width: 40%;
    line-height: 100%;

    @media (max-width: 767px) {
      max-width: 100%;
    }

    span {
      font-size: 25px;
      line-height: 110%;
      display: block;
      margin-top: 30px;

      @media (max-width: 767px) {
        margin-top: 15px;
      }
    }
  }


  &.projects_section .projects_container {
    @media (max-width: 991px) {
      display: grid;
      gap: 35px;
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 767px) {
      gap: 30px;
      grid-template-columns: repeat(1, 1fr);
    }
  }


  &.projects_section:after {
    @media (max-width: 767px) {
      display: none;
    }
  }
}